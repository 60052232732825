<template>
  <div class="p-grid">
    <div class="p-col-12">
      <TopBarBreadCrumb
        :breadcrumbHome="breadcrumbHome"
        :breadcrumbItems="breadcrumbItems"
      />
      <div class="card">
        <h4>{{ i18n.$t('My') }} {{ type }}</h4>

        <div class="p-mb-4">
          <Button
            class="bg-teal-500 text-white rounded p-2"
            @click="
              $router.push({ path: `/events/add`, query: { _type: type } })
            "
          >
            {{ i18n.$t('Add') }} {{ type }}
          </Button>
        </div>
        <DataTable
          :value="RDvdatas"
          dataKey="_id"
          :rowHover="true"
          class="apin-data-table"
          v-model:selection="selected"
        >
          <template #header>
            <div class="table-header">{{ i18n.$t('List of') }} {{ type }}</div>
          </template>
          <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
          <Column field="name" header="Name"></Column>
          <Column field="_type" header="Type"></Column>
          <Column field="date" header="Date">
            <template #body="slotProps">
              <span>
                {{ moment(slotProps.data.date).format('DD/MM/YYYY h:mm') }}
              </span>
            </template>
          </Column>
          <Column
            field="address"
            header="Address"
            :class="{ displaynone: type == 'Task' }"
          ></Column>
          <Column field="importance" header="Importance"></Column>
          <Column :exportable="false">
            <template #body="slotProps">
              <Button
                icon="pi pi-bell"
                class="p-button-rounded p-button-warning p-mr-1"
                @click="
                  $router.push({
                    path: '/events/view',
                    query: { event: slotProps.data._id }
                  })
                "
              />
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="
                  $router.push({
                    path: '/events/edit',
                    query: {
                      event: slotProps.data._id,
                      _type: slotProps.data._type
                    }
                  })
                "
              />
              <ButtonConfirm @accept="onDelete(slotProps.data._id)">
                <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger"
                />
              </ButtonConfirm>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import TopBarBreadCrumb from '@/components/Utilities/TopBarBreadCrumb';
import { FETCH_EVENTS, GET_USERDATA } from '@/store/types';
import { API } from '@/plugins/api';
import useI18n from '@/plugins/useI18n';
import moment from 'moment';

export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
    breadcrumbItems: [
      { label: 'My Task', to: '/events/tasklist?_type=Task' },
      { label: 'My Rdv', to: '/events/rdvlist?_type=Rdv' },
      { label: 'My Event', to: '/events/eventlist?_type=Event' }
    ],
    RDvdata: ''
  }),
  components: {
    TopBarBreadCrumb
  },
  computed: {
    RDvdatas() {
      return this.$store._state.data.events.events;
    }
  },
  setup() {
    const { i18n } = useI18n();
    const store = useStore();
    const route = useRoute();
    const type = ref();
    const userData = computed(() => store.getters[GET_USERDATA]);
    const selected = ref([]);
    const query = route.query;
    
    type.value = query && query._type ? query._type : 'Rdv';

    watch(
      () => [route.query],
      () => {
        const query = route.query;
        type.value = query && query._type ? query._type : 'Rdv';
        initData();
      }
    );

    initData();

    async function initData() {
      await store.dispatch(FETCH_EVENTS, {
        userId: userData.value.id,
        type: type.value
      });
    }

    const api = new API();
    async function onDelete(id) {
      api.delete('event', id, FETCH_EVENTS, 'owner', type.value);
    }

    return { i18n, type, selected, onDelete, moment };
  }
};
</script>